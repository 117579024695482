<template>
	<div>
		<div class="qkxx_title">
			绑定手机号
		</div>
		<div class="qkmyform">
			<el-form
			  :model="ruleForm"
			  :rules="rules"
			  ref="ruleForm"
			  label-width="100px"
			  class="demo-ruleForm"
			>
				<div v-if="phone == ''" class="nodata">未绑定</div>
				<div v-else class="nodata1">
					<span>已绑定：{{ phone }}</span>
				</div>

			  <el-form-item label="手机号" prop="tel" style="width: 50%">
			    <el-input
			      v-model="ruleForm.tel"
			      placeholder="请输入手机号"
			    ></el-input>
			  </el-form-item>
			  <el-form-item label="验证码" prop="code" style="width: 50%">
			    <el-input v-model="ruleForm.code" placeholder="验证码"
			      ><i
			        v-if="able"
			        slot="suffix"
			        style="font-style: normal; color: red; cursor: pointer"
			        @click="sendcode"
			        >{{ btnContent }}</i
			      >
			      <i
			        v-else
			        slot="suffix"
			        style="font-style: normal; color: red; cursor: pointer"
			        >{{ btnContent }}</i
			      >
			    </el-input>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="danger"  style="width: 100px; background: red; border: red" @click="save('ruleForm')"
			      >保存</el-button
			    >
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {bindqktel,searchqk} from "../../../API/QKD/setting.js"
	import {sendfwscode} from "../../../API/security.js"
	import {isUrgent} from "../../../API/QKD/setting";
	export default {
		data() {
			return {
				btnContent: '获取验证码',
				time: 60,//60秒倒计时
				able: true,
				ruleForm: {
				  tel: '',
				  code: ''
				},
				phone:'',
				
				rules: {
						tel: [
						  { required:true, message: '请输入手机号', trigger: 'blur' }
						],
						code: [
						  { required:true, message: '请输入验证码', trigger: 'blur' }
						]
				}
			};
		},
		methods: {
			//发送验证码
			sendcode () {
			  let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 手机号正则验证
			  let phoneNum = this.ruleForm.tel // 输入手机号的value
			  if (!phoneNum) { //
			    // 未输入手机号
			    this.$message.error('请输入手机号');
			    return
			  }
			  if (!reg.test(phoneNum)) {
			    // 手机号不合法
			    this.$message.error('手机号不合法，请重新输入');
			    // this.phoneNum = '' // 这个看情况 ，看是否要删除，获取让用用户手机清除
			  } else {
			    this.btnContent = this.time + "秒后重新发送"
			    let timer = setInterval(() => {
			      if (this.time === 0) { //  当时间等于 0的时候
			        clearInterval(timer) //清除时间
			        // this.disabled = false // 让按钮的样式 为登陆状态
			        this.btnContent = '重新获取验证码' // 登陆状态的value文本
			        this.ruleForm.code = '' // 清除掉验证表单的值
			        this.able = true
			        this.time = 30
			
			      } else {
			        this.btnContent = this.time + "秒后重新发送" // 若时间大于则展示重新获取
			        // this.disabled = true // 判断显示文本的状态
			        this.able = false
			        this.time-- //将时间的一秒秒
			
			      }
			    }, 1000)
			    sendfwscode(phoneNum).then(res => {
			      console.log(res)
			      if (res.data.code == 0) {
			        this.$message({
			          showClose: true,
			          message: '验证码已发送',
			          type: 'success'
			        });
			      } else {
			        this.$message.error("发送失败")
			      }
			    })
			  }
			}, 
			save (ruleForms) {
				this.$refs[ruleForms].validate((valid, obj) => {
				        if (valid) {
					bindqktel(this.ruleForm.code,this.ruleForm.tel, localStorage.getItem('QKId')).then(res => {
					  if (res.data.code == -1) {
					    this.$message.error(res.data.msg)
					
					  } else {
					    location.reload();
					    this.$message({
					      showClose: true,
					      message: '绑定成功',
					      type: 'success'
					    });
				}})
      
        }
      })
    },

			getPhone() {
				let id = localStorage.getItem('QKId')
				searchqk(id).then(res => {
					this.phone=res.data.data.phone;
				})
			},


		},
		mounted() {
			this.getPhone()
		}
	}
	
</script>

<style>
.qkmyform{
	padding: 20px;
}
.nodata {
	text-align: center;
	line-height: 80px;
	color: red;
	text-align:center;
}
.nodata1 {
	text-align: center;
	line-height: 80px;
	color: red;
	text-align:center;
}
</style>